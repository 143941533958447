import React from "react";
import { Drawer } from "@puzzle/ui";

import { AIHome } from "./AIHome";
import { AIPrompts } from "./AIPrompts";
import { AIChat } from "./AIChat";
import Wizard from "components/common/Wizard";
import { AIProvider } from "./AIContext";

export const AskAIDrawer = ({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: () => void;
}) => {
  const steps = [
    { label: "Home", name: "home", component: AIHome },
    {
      label: "Prompts",
      name: "prompts",
      component: AIPrompts,
    },
    {
      label: "Chat",
      name: "chat",
      component: AIChat,
    },
  ];

  return (
    <Drawer open={open} onOpenChange={onOpenChange} css={{ padding: 0 }}>
      <AIProvider>
        <Wizard
          steps={steps}
          render={({ activeStep }) => {
            const Step = activeStep.component;
            return <Step onOpenChange={onOpenChange} />;
          }}
        />
      </AIProvider>
    </Drawer>
  );
};
