import React, { useEffect, useState } from "react";
import { Command } from "cmdk";
import {
  cmdKContainerStyles,
  cmdKFooterStyles,
  cmdKItemIconContainerStyles,
  cmdKItemLabelStyles,
  cmdKOverlayStyle,
  cmdKTagStyles,
} from "./cmdk.css";
import {
  Burn,
  ChevronRight,
  Contact,
  Dashboard,
  Docs,
  Dollar,
  Folder,
  GoTo,
  Keyboard,
  NavAccountant,
  NavChecklist,
  NavInbox,
  PeopleCard,
  Plug,
  Reports,
  Revenue,
  SendFeedback,
  Settings,
  Vendors,
} from "@puzzle/icons";
import { Text } from "ve";
import { keysContainer } from "../ShortcutsModal/shortcutsModal.css";
import { Kbd } from "ve/Kbd/Kbd";
import { useRouter } from "next/router";
import { getClosestStaticRoute, isDynamicRoute, Route } from "lib/routes";
import { hotkeys, Tag } from "@puzzle/ui";
import useAppRouter from "lib/useAppRouter";
import { useShortcutsModal } from "../ShortcutsModal/useShortcutsModal";
import { useIntercom } from "react-use-intercom";
import { useActiveCompany } from "components/companies";
import { InactiveCompanyFragment } from "components/companies/graphql.generated";
import Analytics from "lib/analytics";
import { commandScore } from "./utils";

// Keys to ignore and render the word entirely
// outside a kbd
const IGNORED_KEY = ["then", "or"];

interface Shortcut {
  icon?: React.ReactNode;
  description: string;
  keys?: string[];
  onSelect: () => void;
  keywords?: string[]
}
interface ShortcutsGroup {
  title: string,
  shortcuts: Shortcut[]
}

const shouldIgnoreKey = (key: string) => {
  return IGNORED_KEY.includes(key);
};

const Item = ({
  icon,
  description,
  keys = [],
  onSelect,
  keywords
}: Shortcut) => {
  return (
    <Command.Item onSelect={onSelect} keywords={keywords}>
      <div className={cmdKItemIconContainerStyles}>{icon ? icon : <ChevronRight size={12} />}</div>
      <Text className={cmdKItemLabelStyles}>{description}</Text>
      <div className={keysContainer}>
        {keys.map((key, keyIndex) =>
          shouldIgnoreKey(key) ? key : <Kbd key={`${description}-${keyIndex}`}>{key}</Kbd>
        )}
      </div>
    </Command.Item>
  );
};

const getTagContentForRoute = (route: Route): string | undefined => {
  switch (route) {
    case Route.inbox:
      return "Inbox";
    case Route.checklist:
      return "Monthly checklist";
    case Route.reports:
      return "Reports";
    case Route.accounting:
      return "Accounting";
    case Route.transactions:
      return "Transactions";
    case Route.spending:
      return "Spending";
    case Route.revenue:
      return "Revenue";
    case Route.people:
      return "People";
    case Route.integrations:
      return "Integrations";
    case Route.companySettings:
      return "Settings";
    case Route.vendors:
      return "Vendors";
    case Route.askAccountant:
      return "Ask an accountant";
    default:
      return;
  }
};

export const CmdK = () => {
  const { companies, setActiveCompanyId } = useActiveCompany<true>();
  const { show: showIntercom } = useIntercom();
  const { showShorcutsModal } = useShortcutsModal();
  const router = useRouter();
  const { logout, getCurrentRoute } = useAppRouter();
  const [open, setOpen] = useState(false);
  // hacky way to avoid selecting the first one on open
  const [value, setValue] = useState<string>("none");

  // Copy paste from Company Menu, but it might be helpful
  // to abstract this into a custom hook.
  const setCompany = (company: InactiveCompanyFragment) => {
    Analytics.companyChanged({
      companyId: company.id,
    });

    setActiveCompanyId(company.id);

    if (isDynamicRoute(router.pathname)) {
      router.replace(getClosestStaticRoute(router.pathname));
    }
  };

  const sortedCompanies = [...companies].sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  const SHORTCUTS_GROUPS: ShortcutsGroup[] = [
    {
      title: "Navigation",
      shortcuts: [
        {
          icon: <NavInbox size={12} />,
          description: "Go to Inbox",
          keys: ["G", "then", "I"],
          onSelect: () => {
            router.push(Route.inbox);
          },
        },
        {
          icon: <Dashboard size={12} />,
          description: "Go to Dashboard",
          keys: ["G", "then", "D"],
          onSelect: () => {
            router.push(Route.home);
          },
        },
        {
          icon: <NavChecklist size={12} />,
          description: "Go to Monthly Checklist",
          keys: ["G", "then", "M"],
          onSelect: () => {
            router.push(Route.checklist);
          },
        },
        {
          icon: <Reports size={12} />,
          description: "Go to Reports",
          keys: ["G", "then", "Y"],
          onSelect: () => {
            router.push(Route.reports);
          },
        },
        {
          icon: <Folder size={12} />,
          description: "Go to Accounting",
          keys: ["G", "then", "A"],
          onSelect: () => {
            router.push(Route.accounting);
          },
        },
        {
          icon: <Dollar size={12} />,
          description: "Go to Transactions",
          keys: ["G", "then", "T"],
          onSelect: () => {
            router.push(Route.transactions);
          },
        },
        {
          icon: <Burn size={12} />,
          description: "Go to Spending",
          keys: ["G", "then", "S"],
          onSelect: () => {
            router.push(Route.spending);
          },
        },
        {
          icon: <Revenue size={12} />,
          description: "Go to Revenue",
          keys: ["G", "then", "R"],
          onSelect: () => {
            router.push(Route.revenue);
          },
        },
        {
          icon: <PeopleCard size={12} />,
          description: "Go to People",
          keys: ["G", "then", "E"],
          onSelect: () => {
            router.push(Route.people);
          },
        },
        {
          icon: <Plug size={12} />,
          description: "Go to Integrations",
          keys: ["G", "then", "C"],
          onSelect: () => {
            router.push(Route.integrations);
          },
        },
        {
          icon: <Settings size={12} />,
          description: "Go to Settings",
          keys: ["G", "then", "P"],
          onSelect: () => {
            router.push(Route.companySettings);
          },
        },
        {
          icon: <Vendors size={12} />,
          description: "Go to Vendors",
          keys: ["G", "then", "V"],
          onSelect: () => {
            router.push(Route.vendors);
          },
        },
      ],
    },
    {
      title: "Help",
      shortcuts: [
        {
          icon: <Keyboard />,
          description: "Keyboard shortcuts",
          keys: ["⇧", "/"],
          keywords: ['help'],
          onSelect: () => {
            showShorcutsModal();
          },
        },
        {
          icon: <NavAccountant size={12} />,
          description: "Ask an Accountant",
          keys: ["⇧", "A"],
          keywords: ['help'],
          onSelect: () => {
            router.push(Route.askAccountant);
          },
        },
        {
          icon: <Docs />,
          description: "Help docs ↗",
          keys: [],
          keywords: ['help'],
          onSelect: () => {
            window.open("https://help.puzzle.io", "_blank");
          },
        },
        {
          icon: <SendFeedback />,
          description: "Send Feedback",
          keys: ["⌘", "⇧", "H", ],
          keywords: ['help'],
          onSelect: () => {
            showIntercom();
          },
        },
        {
          icon: <Contact />,
          description: "Contact",
          keys: ["⌘", "⇧", "H"],
          keywords: ['help'],
          onSelect: () => {
            showIntercom();
          },
        },
      ],
    },
  ];

  useEffect(() => {
    const unsubscribeHotkeys = hotkeys(window, {
      "g i": () => {
        router.push(Route.inbox);
      },
      "g d": () => {
        router.push(Route.home);
      },
      "g m": () => {
        router.push(Route.checklist);
      },
      "g y": () => {
        router.push(Route.reports);
      },
      "g a": () => {
        router.push(Route.accounting);
      },
      "g t": () => {
        router.push(Route.transactions);
      },
      "g s": () => {
        router.push(Route.spending);
      },
      "g r": () => {
        router.push(Route.revenue);
      },
      "g e": () => {
        router.push(Route.people);
      },
      "g c": () => {
        router.push(Route.integrations);
      },
      "g p": () => {
        router.push(Route.companySettings);
      },
      "g v": () => {
        router.push(Route.vendors);
      },
      "Shift+A": () => {
        router.push(Route.askAccountant);
      },
      "Meta+Shift+H": (e) => {
        e.preventDefault();
        showIntercom();
      },
      "Alt+Shift+KeyQ": () => {
        logout();
      },
    });
    const unsubscribeCmdK = hotkeys(
      window,
      {
        "Meta+k": (e) => {
          e.preventDefault();
          setOpen((open) => !open);
        },
      },
      false
    );
    return () => {
      unsubscribeHotkeys();
      unsubscribeCmdK();
    };
  }, [router, logout]);

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      setValue("none");
    }
    setOpen(open);
  };

  // I need the first segment of the route.
  // Example: /inbox/updates -> /inbox
  // /accounting/ledger-reconciliations -> /accounting
  const segmentedRoute = `/${getCurrentRoute().split("/")[1]}` as Route;
  const tagContent = getTagContentForRoute(segmentedRoute);

  return (
    <Command.Dialog
      open={open}
      onOpenChange={handleOpenChange}
      value={value}
      onValueChange={setValue}
      filter={commandScore}
    >
      <div className={cmdKOverlayStyle} onClick={() => handleOpenChange(false)} />
      <div className={cmdKContainerStyles}>
        <div className={cmdKTagStyles}>
          {tagContent && <Tag css={{ borderRadius: "$1" }}>{tagContent}</Tag>}
        </div>
        <Command.Input placeholder="Type a command or search" />
        <Command.List>
          <Command.Empty>No results found.</Command.Empty>
          {SHORTCUTS_GROUPS.map((group, groupIndex) => (
            <Command.Group heading={group.title} key={`kbd-${group.title}-${groupIndex}`}>
              {group.shortcuts.map((shortcut, shortcutIndex) => (
                <Item
                  icon={shortcut.icon}
                  description={shortcut.description}
                  keys={shortcut.keys}
                  key={`kbd-${group.title}-${groupIndex}-${shortcutIndex}-${shortcutIndex}`}
                  keywords={shortcut.keywords}
                  onSelect={() => {
                    shortcut.onSelect();
                    setOpen(false);
                  }}
                />
              ))}
            </Command.Group>
          ))}
          {companies.length > 1 && (
            <Command.Group heading="Switch company">
              {sortedCompanies.map((company) => (
                <Item
                  key={`switch-company-${company.id}`}
                  icon={<GoTo size={12} />}
                  description={company.name}
                  onSelect={() => {
                    setCompany(company);
                  }}
                />
              ))}
            </Command.Group>
          )}
        </Command.List>
        <div className={cmdKFooterStyles}>
          <div className={keysContainer}>
            <Kbd>↑</Kbd>
            <Kbd>↓</Kbd>
            <div>Navigate items</div>
          </div>
          <div className={keysContainer}>
            <Kbd>Esc</Kbd>
            <div>Close</div>
          </div>
        </div>
      </div>
    </Command.Dialog>
  );
};
