import React, { useEffect } from "react";
import { Drawer, hotkeys, IconButton } from "@puzzle/ui";
import {
  containerStyles,
  groupTitleStyles,
  headerStyles,
  keyGroupStyles,
  keysContainer,
  shortkeyDescriptionStyles,
  shortkeyRowStyles,
} from "./shortcutsModal.css";
import { Close } from "@puzzle/icons";
import { Kbd } from "ve/Kbd/Kbd";
import { useShortcutsModal } from "./useShortcutsModal";

// undefined = a pause between keys = then
interface Shortcut {
  description: string;
  keys: string[];
  disabled?: boolean;
}

interface ShortcutGroup {
  title: string;
  shortcuts: Shortcut[];
}

const SHORTCUT_LIST: ShortcutGroup[] = [
  {
    title: "General",
    shortcuts: [
      {
        description: "Open command menu",
        keys: ["⌘", "K"],
        disabled: true,
      },
      {
        description: "Back",
        keys: ["Esc"],
      },
      {
        description: "Open Search",
        keys: ["/"],
      },
      {
        description: "Previous",
        keys: ["↑", "or", "K"],
      },
      {
        description: "Next",
        keys: ["↓", "or", "J"],
      },
      {
        description: "Help",
        keys: ["⇧", "/"],
      },
    ],
  },
  {
    title: "Navigation",
    shortcuts: [
      {
        description: "Go to Inbox",
        keys: ["G", "then", "I"],
      },
      {
        description: "Go to Dashboard",
        keys: ["G", "then", "D"],
      },
      {
        description: "Go to Monthly Checklist",
        keys: ["G", "then", "M"],
      },
      {
        description: "Go to Reports",
        keys: ["G", "then", "Y"],
      },
      {
        description: "Go to Accounting",
        keys: ["G", "then", "A"],
      },
      {
        description: "Go to Transactions",
        keys: ["G", "then", "T"],
      },
      {
        description: "Go to Spending",
        keys: ["G", "then", "S"],
      },
      {
        description: "Go to Vendors",
        keys: ["G", "then", "V"],
      },
      {
        description: "Go to Revenue",
        keys: ["G", "then", "R"],
      },
      {
        description: "Go to People",
        keys: ["G", "then", "E"],
      },
      {
        description: "Go to Integrations",
        keys: ["G", "then", "C"],
      },
      {
        description: "Go to Settings",
        keys: ["G", "then", "P"],
      },
    ],
  },
];

// Keys to ignore and render the word entirely
// outside a kbd
const IGNORED_KEY = ["then", "or"];

const shouldIgnoreKey = (key: string) => {
  return IGNORED_KEY.includes(key);
};

export const ShortcutsModal = () => {
  const { isVisible, toggleShortcutsModal, hideShortcutsModal } = useShortcutsModal();
  useEffect(() => {
    const unsubscribe = hotkeys(window, {
      "Shift+Slash": () => {
        toggleShortcutsModal();
      },
    });
    return () => {
      unsubscribe();
    };
  }, [toggleShortcutsModal]);
  return (
    <Drawer
      css={{ zIndex: "2147483003", width: "400px" }} // Thanks Intercom
      open={isVisible}
      onOpenChange={(open) => {
        if (!open) hideShortcutsModal();
      }}
    >
      <div className={containerStyles}>
        <div className={headerStyles}>
          Keyboard Shortcuts
          <IconButton onClick={() => hideShortcutsModal()}>
            <Close fill="currentColor" />
          </IconButton>
        </div>
        {SHORTCUT_LIST.map((group, index) => (
          <div key={`${group.title}-${index}`} className={keyGroupStyles}>
            <div className={groupTitleStyles}>{group.title}</div>
            {group.shortcuts
              .filter((shortcut) => !shortcut.disabled)
              .map((shortcut, shortcutIndex) => (
                <div className={shortkeyRowStyles} key={`${group.title}-${index}-${shortcutIndex}`}>
                  <div className={shortkeyDescriptionStyles}>{shortcut.description}</div>
                  <div className={keysContainer}>
                    {shortcut.keys.map((key, keyIndex) =>
                      shouldIgnoreKey(key) ? (
                        key
                      ) : (
                        <Kbd key={`${group.title}-${index}-${shortcutIndex}-${keyIndex}`}>
                          {key}
                        </Kbd>
                      )
                    )}
                  </div>
                </div>
              ))}
          </div>
        ))}
      </div>
    </Drawer>
  );
};
