import React, { useEffect, useState } from "react";

import { Button, Dialog, Stack, Text } from "@puzzle/ui";

import { useUpsertMessageSettingsMutation } from "components/users/graphql.generated";
import { MembershipRole } from "graphql/types";
import useSelf from "components/users/useSelf";
import { useActiveCompany } from "components/companies";

import UpgradeButton from "components/featureGate/UpgradeButton";

import { MonthlyExceededAlert } from "components/settings/Billing/MonthlyExceededAlert";
import { useBilling } from "components/settings/Billing/useBilling";
import { Box, S } from "ve";

const SETTING_KEY = "trial-expired-modal-seen";

export const TrialExpiredModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { loading, exceeding } = useBilling();
  const { messageSettings } = useSelf();
  const [upsertMessageSettings] = useUpsertMessageSettingsMutation();

  const { membership, isOnFreeTrial, isOnPaidPlan, membershipRole } = useActiveCompany<true>();
  const isAdmin = membershipRole === MembershipRole.Admin;
  const isOwner = membership?.isOwner || false;
  const freeTrialExpired = !isOnFreeTrial && !isOnPaidPlan;
  const hasSeen = !!messageSettings[SETTING_KEY];

  const markAsSeen = () => {
    upsertMessageSettings({
      variables: {
        input: {
          key: SETTING_KEY,
          value: true,
        },
      },
    });
  };

  useEffect(() => {
    if ((isOwner || isAdmin) && !hasSeen && freeTrialExpired) {
      setIsModalVisible(true);
    }
  }, [isOwner, hasSeen, freeTrialExpired, isAdmin]);

  const handleOpenChange = (open: boolean) => {
    if (open) {
      return;
    }
    markAsSeen();
    setIsModalVisible(false);
  };

  return (
    <Dialog size="regular" open={isModalVisible} onOpenChange={handleOpenChange}>
      <Dialog.Title css={{ textVariant: "$headingM" }}>Your trial has expired.</Dialog.Title>

      <Dialog.Body css={{ padding: "$3" }}>
        <Stack direction="vertical" gap="2">
          <Stack gap="2">
            <Text variant="body" css={{ color: "$gray400" }}>
              Now that your free trial of our <Text css={{ color: "$gray200" }}>Advanced</Text> plan
              has ended, we've downgraded you to the{" "}
              <Text css={{ color: "$gray200" }}>Formation</Text> (Free) plan.
            </Text>
            {exceeding && <MonthlyExceededAlert />}
            <Text css={{ textVariant: "$bodyS", color: "$gray400" }}>
              Don't worry, this change won't delete or undo any of the work you've done to date.
            </Text>
          </Stack>
          <Box
            css={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: S["2"] }}
          >
            {!exceeding && (
              <Button variant={"secondary"} onClick={() => handleOpenChange(false)}>
                Stay on free plan
              </Button>
            )}
            <UpgradeButton
              title={"Upgrade"}
              showIcon={false}
              onClick={() => handleOpenChange(false)}
            />
          </Box>
        </Stack>
      </Dialog.Body>
    </Dialog>
  );
};
